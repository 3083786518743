<template>
  <div
    v-if="!cart.isCartNotEmpty"
    class="flex flex-col gap-3"
  >
    <span class="text-center font-medium my-3">
      El carrito esta vacio
    </span>
    <a
      class="w-full bg-custom-900 p-4 text-2xl font-medium text-white"
      href="/"
    >
      Seguir comprando
    </a>
  </div>
  <div v-else>
    <div class="flex flex-col gap-12 lg:w-[800px] w-full">
      <div class="flex flex-col gap-5">
        <h2 class="font-medium text-3xl text-custom-900">
          Libros seleccionados
        </h2>
        <div class="bg-white dark:bg-black  border-2 border-custom-800">
          <cart-details :show-total="true" />
        </div>
      </div>
      <div class="flex flex-col gap-5">
        <h2 class="font-medium text-3xl text-custom-900">
          Informacion
        </h2>
        <div class="sm:p-5 p-2 border-2 border-custom-800">
          <form
            id="form-shop"
            class="flex flex-col gap-4"
            method="POST"
            action="/order"
          >
            <input
              type="hidden"
              name="_token"
              :value="crsfToken"
            >
            <input
              type="hidden"
              name="products"
              :value="JSON.stringify(cart.itemsToJson)"
            >
            <div class="grid grid-cols-12 gap-2 sm:gap-4 items-center bg-gray-50 sm:bg-transparent p-3 sm:p-0">
              <div class="col-span-12 sm:col-span-4 font-medium sm:text-end">
                Nombre
              </div>
              <div class="col-span-12 sm:col-span-4 text-end">
                <input
                  class=" rounded-sm w-full h-8 border border-gray-500"
                  type="text"
                  name="name"
                >
              </div>
            </div>
            <div class="grid grid-cols-12 gap-2 sm:gap-4 items-center bg-gray-50 sm:bg-transparent p-3 sm:p-0">
              <div class="col-span-12 sm:col-span-4 font-medium sm:text-end">
                Correo
              </div>
              <div class="col-span-12 sm:col-span-4 text-end">
                <input
                  class=" rounded-sm w-full h-8 border border-gray-500"
                  type="email"
                  name="email"
                >
              </div>
            </div>
            <div class="grid grid-cols-12 gap-2 sm:gap-4 items-center bg-gray-50 sm:bg-transparent p-3 sm:p-0">
              <div class="col-span-12 sm:col-span-4 gap-2 font-medium  flex items-center sm:justify-end">
                <p>
                  Numero de telefono
                </p>
                <p class="text-sm font-normal hidden sm:display">
                  +56 9 1234 1234
                </p>
              </div>
              <div class="col-span-12 sm:col-span-4 text-end">
                <input
                  class=" rounded-sm w-full h-8 border border-gray-500"
                  type="tel"
                  name="phone"
                >
              </div>
            </div>
            <div class="grid grid-cols-12 gap-1 sm:gap-2 bg-gray-50 sm:bg-transparent p-3 sm:p-0 items-center">
              <div class="col-span-12 sm:col-span-4 sm:text-end font-medium">
                Metodo de despacho
              </div>
              <div class="col-span-12 sm:col-span-4 text-end">
                <div class="flex flex-col gap-2 sm:bg-gray-50 sm:p-3">
                  <label
                    v-for="shipping in shippingMethods"
                    :key="shipping.id"
                    class="flex items-center gap-2"
                  >
                    <input
                      type="radio"
                      name="shipping_method"
                      :value="shipping.value"
                    >
                    {{ shipping.label }}
                  </label>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-12 gap-1 sm:gap-2 bg-gray-50 sm:bg-transparent p-3 sm:p-0 items-center">
              <div class="col-span-12 sm:col-span-4 sm:text-end font-medium">
                Metodo de pago
              </div>
              <div class="col-span-12 sm:col-span-4 text-end">
                <div class="flex flex-col gap-2 sm:bg-gray-50 sm:p-3">
                  <label
                    v-for="payment in paymentMethods"
                    :key="payment.id"
                    class="flex items-center gap-2"
                  >
                    <input
                      type="radio"
                      name="payment_method"
                      :value="payment.value"
                    >
                    {{ payment.label }}
                  </label>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-12 gap-1 sm:gap-2 bg-gray-50 sm:bg-transparent p-3 sm:p-0 items-center">
              <div class="col-span-12 sm:col-span-4 sm:text-end font-medium">
                Direccion de despacho
              </div>
              <div class="col-span-12 sm:col-span-4 text-end">
                <input
                  class="rounded-sm w-full h-8 border border-gray-500"
                  type="text"
                  name="address"
                >
              </div>
            </div>
          </form>
        </div>
      </div>
      <button
        type="submit"
        form="form-shop"
        class="w-full bg-custom-900 p-4 text-2xl font-medium text-white"
      >
        Confirmar compra
      </button>
    </div>
  </div>
</template>
<script>

import { useCartStore } from "@/stores/cart";

import CartDetails from "@/components/cart/cart-details.vue";

export default {
  components: {
    CartDetails,
  },
  props: {
    crsfToken: {
      type: String,
      required: true,
    },
    errors: {
      type: Array,
      required: false,
      default: () => [],
    },
    old: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    shippingMethods: {
      type: Array,
      required: true,
    },
    paymentMethods: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return {
      cart: useCartStore(),
    };
  },
};

</script>
