<template>
  <div
    class="sm:px-5 sm:py-4 sm:w-44 p-3 border-2 border-white flex gap-2 items-center"
    @click="showCart()"
  >
    <v-icon
      icon="mdi:cart-outline"
      class="text-2xl"
    />
    <div v-if="cart.isCartNotEmpty">
      {{ cart.itemsCount }} {{ cart.itemsCount === 1 ? '' : 'libros' }}
    </div>
    <div v-else>
      <span class="block sm:hidden">
        Vacio
      </span>
      <span class="hidden sm:block">
        Carrito vacio
      </span>
    </div>
  </div>
</template>
<script>

import { useCartStore } from '@/stores/cart';

export default {
  emits: [
    'showCart',
  ],
  setup() {
    return {
      cart: useCartStore(),
    };
  },
  methods: {
    showCart() {
      this.$emit('showCart');
    },
  },
};

</script>
