
import api from '../index';

export default {
  uploadImages(id, formData) {
    return api({
      method: 'post',
      url: `/admin/products/${id}/upload-images`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  updateImages(id, formData) {
    return api({
      method: 'put',
      url: `/admin/products/${id}/update-images`,
      data: formData,
    });
  },
  getImages(id) {
    return api({
      method: 'get',
      url: `/admin/products/${id}/get-images`,
    });
  },
};
