<template>
  <div class="w-full relative h-full ">
    <!-- <div class="absolute left-0 top-0 2xl:px-3 h-full flex items-center">
      <v-icon
        icon="mdi:chevron-left"
        class="text-4xl"
        @click="prev"
      />
    </div> -->
    <div

      class="w-full left-0 top-0 h-full flex justify-center"
    >
      <img
        :src="carouselImage"
        alt=""
        class="object-contain object-center h-full max-h-60 sm:max-h-none"
      >
    </div>
    <!-- <div class="absolute right-0 top-0 2xl:px-3 h-full flex items-center">
      <v-icon
        icon="mdi:chevron-right"
        class="text-4xl"
        @click="next"
      />
    </div> -->
  </div>
</template>
<script>

import CarouselImage from '/resources/images/carousel.jpeg';

export default {
  name: 'Carousel',
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [[]],
    },
  },
  data() {
    return {
      activeIndex: 0,
      carouselImage: CarouselImage
    };
  },
  methods: {
    next() {
      if (this.activeIndex < this.items.length - 1) {
        this.activeIndex++;
      } else {
        this.activeIndex = 0;
      }
    },
    prev() {
      if (this.activeIndex > 0) {
        this.activeIndex--;
      } else {
        this.activeIndex = this.items.length - 1;
      }
    },
  },
};
</script>
