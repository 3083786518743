<template>
  <div class="flex flex-row gap-2">
    <slot />
  </div>
</template>
<script>

export default {
};

</script>
