import { createApp } from 'vue';

import { Icon } from '@iconify/vue';
import { camelizeKeys } from 'humps';

import * as Sentry from '@sentry/vue';

import i18n from './locales';
import pinia from './stores';

import VCurrency from './components/shared/currency.vue';
import VTipTap from './components/shared/tiptap/index.vue';

import CartPicker from './components/cart/picker.vue';
import CartModal from './components/cart/modal/index.vue';
import CartDetails from './components/cart/cart-details.vue';

import VSearch from './components/layout/search/search.vue';
import VFeatures from './components/features.vue';
import VCarousel from './components/shared/carousel/index.vue';
import VCheckout from './components/checkout/index.vue';

import AdminProductImagesEditor from './components/admin/products/images-editor.vue';
import AdminProductImages from './components/admin/products/images.vue';

import AdminProductTagsEditor from './components/admin/products/tags-editor.vue';

import StoreIndexTagsList from './components/store/index/tags-list.vue';
import StoreProductImageView from './components/store/product/image-view.vue';

const app = createApp();

import.meta.glob([
  '../../resources/images/**',
  '../../resources/fonts/**',
]);

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN || null,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  integrations: [
    new Sentry.BrowserTracing(),
  ],
  sampleRate: import.meta.env.VITE_SENTRY_SAMPLE_RATE || false,
  tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE || false,
});

app.use(i18n);
app.use(pinia);

app.config.globalProperties.$filters = {
  camelizeKeys,
};

app.component('VIcon', Icon);
app.component('VCurrency', VCurrency);
app.component('VTiptap', VTipTap);

app.component('CartPicker', CartPicker);
app.component('CartModal', CartModal);
app.component('CartDetails', CartDetails);
app.component('VSearch', VSearch);
app.component('VFeatures', VFeatures);
app.component('VCheckout', VCheckout);

app.component("VCarousel", VCarousel);

app.component('AdminProductImagesEditor', AdminProductImagesEditor);
app.component('AdminProductImages', AdminProductImages);
app.component('AdminProductTagsEditor', AdminProductTagsEditor);

app.component('VStoreIndexTagsList', StoreIndexTagsList);
app.component('VStoreProductImageView', StoreProductImageView);

app.mount('#app');
