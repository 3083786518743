<template>
  <div class="flex">
    <input
      v-model="queryString"
      class="sm:h-14 h-11 w-full px-2 border-2 border-custom-900 dark:bg-black dark:text-white"
      type="search"
      @keyup.enter="search(queryString)"
    >
    <button
      class="sm:w-24 w-24 h-11 text-sm text-md bg-custom-900 text-white sm:h-14"
      @click="search(queryString)"
    >
      Buscar
    </button>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      queryString: this.value,
    };
  },
  methods: {
    search(queryString) {
      window.location.href = `/search?query=${queryString}`;
    },
  },
};
</script>
