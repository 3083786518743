<template>
  <div>
    <cart-modal-button
      @show-cart="showCart"
    />
    <Transition mode="out-in">
      <cart-modal
        v-if="shouldShowModal"
        @hide-modal="hideModal"
      />
    </Transition>
  </div>
</template>
<script>

import { useCartStore } from '@/stores/cart';
import { useEventBus } from '@vueuse/core';

import CartModalButton from './button.vue';
import CartModal from './modal.vue';

export default {
  components: {
    CartModalButton,
    CartModal,
  },
  setup() {
    return {
      cart: useCartStore(),
    };
  },
  data() {
    return {
      shouldShowModal: false,
      bus: {
        cartShow: useEventBus('cart.show'),
        cartHide: useEventBus('cart.hide'),
      },
    };
  },
  mounted() {
    this.bus.cartShow.on(this.showCart);
    this.bus.cartShow.on(this.showCart);
  },
  methods: {
    showCart() {
      this.shouldShowModal = true;
      document.body.style.overflow = 'hidden';
    },
    hideModal() {
      this.shouldShowModal = false;
      document.body.style.overflow = 'auto';
    },
  },
};
</script>
<style>
.v-enter-active,
.v-leave-active {
  @apply transition;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

