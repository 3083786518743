<template>
  <modal>
    <div class="ml-auto">
      <OnClickOutside
        class="h-full w-full"
        @trigger="() => hideModal()"
      >
        <div class="md:w-[680px] w-screen min-h-full bg-white text-black dark:bg-gray-900 dark:text-white dark:text-opacity-90 p-7">
          <div
            v-if="isCartNotEmpty"
            class="flex flex-col gap-8 bg-white dark:bg-gray-900"
          >
            <div class="flex items-center">
              <h2 class="font-semibold">
                Carrito
              </h2>
              <div
                class="ml-auto"
                @click="hideModal"
              >
                <v-icon
                  icon="bx:bx-x"
                  class="text-2xl"
                />
              </div>
            </div>
            <cart-details />
            <div class="flex justify-end sm:gap-8 gap-3 items-center">
              <a
                class="text-custom-800 p-3 font-bold"
                @click="hideModal"
              >
                Cerrar
              </a>
              <a
                class="bg-custom-800 text-white font-medium py-3 px-3 sm:px-16"
                href="/checkout"
              >
                Confirmar
              </a>
            </div>
          </div>
          <div
            v-else
            class="flex flex-col gap-3"
          >
            <span class="text-center font-medium my-3">
              El carrito esta vacio
            </span>
            <button
              class="bg-custom-800 text-white font-medium py-3 sm:px-16"
              @click="hideModal"
            >
              Seguir comprando
            </button>
          </div>
          <div class="flex justify-end sm:gap-8 items-center" />
        </div>
      </OnClickOutside>
    </div>
  </modal>
</template>
<script>

import { useCartStore } from '@/stores/cart';
import modal from '@/components/shared/modal.vue';
import cartDetails from '@/components/cart/cart-details.vue';
import { OnClickOutside } from '@vueuse/components';

export default {
  components: {
    modal,
    cartDetails,
    OnClickOutside,
  },
  emits: [
    'hideModal',
  ],
  setup() {
    return {
      cart: useCartStore(),
    };
  },
  computed: {
    isCartNotEmpty() {
      return this.cart.isCartNotEmpty;
    },
  },
  methods: {
    hideModal() {
      this.$emit('hideModal');
    },
  },
};
</script>
