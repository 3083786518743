<template>
  <div class="flex flex-col divide-y-2 divide-gray-200 dark:divide-gray-700">
    <div
      v-for="product in isFromOrder ? cartFromOrder : cart.products"
      :key="product.id"
      class="flex flex-col py-6 px-3 sm:px-6 "
    >
      <div
        v-for="variation in product.variations "
        :key="variation.id"
        class="flex items-center sm:gap-7 gap-3 "
      >
        <button
          v-if="!viewOnly"
          @click="cart.removeProductVariationById(variation.id)"
        >
          <v-icon
            icon="mdi:delete-outline"
            class="text-lg"
          />
        </button>
        <div class="w-full">
          {{ product.name }}
        </div>
        <div class="font-semibold w-6">
          {{ variation.quantity }}
        </div>
        <v-currency
          class="font-semibold w-24 text-end"
          :amount="variation.quantity * variation.price"
        />
        <div
          v-if="showTotal"
          class="sm:w-28"
        />
      </div>
    </div>
    <div
      v-if="showTotal"
      class="w-full flex justify-end pr-6 py-8"
    >
      <p class="font-semibold">
        Total
      </p>
      <div class="w-24 text-end">
        <v-currency
          class="font-semibold w-24 text-end"
          :amount="totalPrice"
        />
      </div>
    </div>
  </div>
</template>

<script>

import { useCartStore } from "@/stores/cart";

export default {
  props: {
    showTotal: {
      type: Boolean,
      required: false,
      default: false,
    },
    viewOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    productsFromOrder: {
      type: Object,
      required: false,
      default: () => ({}),
    }
  },
  setup() {
    return {
      cart: useCartStore(),
    };
  },
  data() {
    return {
      cartFromOrder: {},
    };
  },
  mounted() {
    if (this.productsFromOrder) {
      this.cartFromOrder = this.fromOrderToCart();
    }

    if (location.href.includes('/order/')) {
      this.cart.clear();
    }
  },
  methods: {
    fromOrderToCart() {
      return Object.values(this.productsFromOrder).reduce((acc, variation) => {
        acc[variation.product_id] = {
          id: variation.product_id,
          name: variation.product.name,
          price: variation.price,
          variations: {
            [variation.id]: {
              id: variation.id,
              quantity: variation.pivot.quantity,
              price: variation.pivot.unit_price,
            },
          },
        }
        return acc;
      }, {});
    },
  },
  computed: {
    isFromOrder() {
      return Object.keys(this.productsFromOrder).length > 0;
    },
    totalPrice() {
      return Object.values(this.isFromOrder ? this.cartFromOrder : this.cart.products).reduce((acc, product) => {
        return acc + Object.values(product.variations).reduce((acc, variation) => {
          return acc + variation.quantity * variation.price;
        }, 0);
      }, 0);
    },
  },
};

</script>
