import api from '../index';

export default {
  index(params = {}) {
    return api({
      method: 'get',
      url: '/api/store/products',
      params,
    });
  },
  show(id, params = {}) {
    return api({
      method: 'get',
      url: `/api/store/products/${id}`,
      params,
    });
  },
};
