import productVariationsApi from "@/api/store/product-variations";
import productsApi from "@/api/store/products";

export default {
  /*
  * This function takes a ProductVariation id, and an optional quantity argument,
  * and append the variation to the cart.
  */
  async addProductVariationById(id, quantity=1) {
    const variation = (await productVariationsApi.show(id, {
      withProduct: true,
    })).data.data;
    const product = (await productsApi.show(variation.product.id)).data.data;

    if (!this.products[product.id]) {
      this.products[product.id] = product;
      this.products[product.id].variations = {};
    }

    if (!this.products[product.id].variations[variation.id]) {
      this.products[product.id].variations[variation.id] = variation;
      this.products[product.id].variations[variation.id].quantity = 0;
    }

    this.products[product.id].variations[variation.id].quantity += quantity;

    this.productsTotal += variation.price * quantity;
  },

  /*
  * This function takes a ProductVariation id, and an optional quantity argument
  * and remove the variation from cart. If variation quantity is then 0 or less,
  * then it is emptied.
  */
  async removeProductVariationById(variationId, quantity=1) {
    const productId = Object.values(this.products)
      .find(product => Object.values(product.variations)
        .find(variation => variation?.id === variationId)?.id)?.id;

    if (!productId) {
      return;
    }

    const variation = this.products[productId].variations[variationId];

    this.products[productId].variations[variationId].quantity -= quantity;
    this.productsTotal -= variation.price * quantity;

    if (this.products[productId].variations[variationId].quantity <= 0) {
      delete this.products[productId].variations[variationId];
    }

    if (Object.keys(this.products[productId].variations).length <= 0) {
      delete this.products[productId];
    }
  },

  clear() {
    this.products = {};
    this.productsTotal = 0;
  },

  getCountOfVariation(productId, variationId) {
    return this.products[productId]?.variations[variationId]?.quantity ?? 0;
  },
};
