<template>
  <div
    class="flex flex-wrap items-center gap-3"
  >
    <div
      v-for="image in images"
      :key="image.uuid"
      class="relative"
    >
      <a
        target="_blank"
        :href="image.original_url"
      >
        <img
          :src="image.original_url"
          class="max-h-28 rounded border-2 bg-cover"
        >
      </a>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    images: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
