import { defineStore } from 'pinia';

import actions from './actions';
import state from './state';
import getters from './getters';

export const useCartStore = defineStore('cart', {
  actions,
  state,
  getters,

  persist: true,
});
