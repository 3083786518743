<template>
  <div>
    <VueMultiselect
      v-model="selected"
      track-by="id"
      label="full_name"
      :multiple="true"
      :options="tags"
    />
    <input
      type="hidden"
      :value="selectedTagsIds"
      name="secondary_tags"
    >
  </div>
</template>
<script>

import VueMultiselect from 'vue-multiselect';

export default {
  components: { VueMultiselect },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    tags: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      selected: this.tags.filter(tag => this.value.includes(tag.id)),
    };
  },
  computed: {
    selectedTagsIds () {
      return JSON.stringify(this.selected.map(tag => tag.id));
    },
  },
};

</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
