<template>
  <div>
    <select
      v-if="features.productVariations"
      v-model="selectedVariationId"
    >
      <option
        v-for="variation in variations"
        :key="variation.id"
        :value="variation.id"
      >
        {{ variation.name }}
      </option>
    </select>
    <div class="h-full w-full">
      <div v-if="productLoading || temporalStock > 0">
        <Transition mode="out-in">
          <button
            v-if="!added"
            class="md:h-12 h-16 px-3 xl:h-14 flex gap-2  items-center justify-center w-full  bg-custom-800 text-white"
            @click.prevent="addToCart(selectedVariationId)"
          >
            <v-icon icon="mdi:cart" />
            <p>
              Agregar al carrito
            </p>
          </button>
          <div
            v-else
            class="md:h-12 h-16 px-3 xl:h-14 flex items-center text-sm justify-center w-full
        text-center bg-white text-custom-800 font-black border-4
        border-custom-800 gap-2"
          >
            <v-icon icon="mdi:cart-check" />
            <p>
              AGREGADO
            </p>
          </div>
        </Transition>
      </div>
      <div
        v-else
        class="md:h-12 h-16 px-3 xl:h-14 flex items-center text-sm justify-center w-full
        text-center bg-custom-100 text-custom-800 font-black border-4
        border-custom-800 gap-2"
      >
        <v-icon icon="mdi:cart-off" />
        <p v-if="product.stock == 0">
          AGOTADO
        </p>
        <p v-else-if="temporalStock == 0">
          NO QUEDAN MÁS
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { useCartStore } from '@/stores/cart';
import productsApi from '@/api/store/products';
import { useEventBus } from '@vueuse/core';

export default {
  props: {
    productId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const cartStore = useCartStore();

    return {
      cartStore,
    };
  },
  data() {
    return {
      variations: [],
      product: null,
      selectedVariationId: null,
      added: false,
      addedTimeout: null,
      temporalStock: 0,
      productLoading: true,
      showCartBus: useEventBus('cart.show'),
    };
  },
  async mounted() {
    this.product = (await productsApi.show(this.productId, {
      withVariations: true,
      withStock: true,
    })).data.data;

    this.variations = this.product.variations;

    if (! this.features.productVariations) {
      this.selectedVariationId = this.variations[0].id;
    }
    this.temporalStock =
      this.product.stock
      - this.cartStore.getCountOfVariation(this.product.id, this.selectedVariationId);

    this.productLoading = false;
  },
  methods: {
    addToCart(id) {
      if (this.productLoading) {
        return;
      }

      if (this.product.stock <= 0) {
        return;
      }

      this.temporalStock -= 1;
      this.added = true;
      this.cartStore.addProductVariationById(id);

      setTimeout(() => {
        this.showCartBus.emit();
      }, 700);

      this.addedTimeout = setTimeout(() => {
        this.added = false;
      }, 3000);
    },
  },
};

</script>

<style>
.v-enter-active,
.v-leave-active {
  @apply transition;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
