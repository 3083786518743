export default {
  isCartNotEmpty(state) {
    return Object.keys(state.products).length > 0;
  },
  itemsCount(state) {
    let count = 0;

    Object.values(state.products).forEach(product => {
      Object.values(product.variations).forEach(variation => {
        count += variation.quantity;
      });
    });

    return count;
  },
  itemsToJson(state) {
    return Object.values(state.products).reduce((acc, product) =>
      acc.concat(Object.values(product.variations).map(variation => ({
          id: variation.id,
          quantity: variation.quantity,
        }),
      )),
    []);
  },
};
