<template>
  <div class="flex flex-row border-b pb-2 px-2">
    <slot />
  </div>
</template>
<script>

export default {
};

</script>
