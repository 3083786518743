import api from '../index';

export default {
  show(id, params = {}) {
    return api({
      method: 'get',
      url: `/api/store/product-variations/${id}`,
      params,
    });
  },
};
