<template>
  <div class="flex gap-4 flex-col-reverse md:flex-row md:w-full md:justify-start">
    <div
      v-if="imageList.length > 0"
      class="h-16 w-full md:w-28 flex flex-row gap-4 md:h-full md:min-w-28 md:flex-col md:items-center md:justify-evenly"
    >
      <img
        v-for="[i, image] in imageList.entries()"
        :key="image.uuid"
        class="w-16 md:h-28 object-cover border p-1 border-custom-100"
        :src="image.original_url"
        @click="imageIndex = i"
      >
      <div
        v-if="imageList.length === 0"
        class="bg-gray-300"
      />
    </div>
    <div
      v-if="imageList.length > 0"
      class="flex items-center justify-center w-full"
    >
      <img
        :src="imageList[imageIndex].original_url"
        class="w-full object-center object-contain md:h-[500px] lg:h-[400px] xl:h-[600px]"
      >
    </div>
    <div
      v-else
      class="bg-custom-100 w-full italic md:w-auto md:h-[500px] lg:h-[400px] xl:h-[600px] xl:w-full flex font-semibold items-center justify-center"
    >
      <p>
        Libro sin imagenes
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    images: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imageIndex: 0,
      imageList: Object.values(this.images),
    };
  },
  methods: {
  },
};
</script>
