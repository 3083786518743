<template>
  <button
    type="button"
    class=""
    :class="{ 'bg-black bg-opacity-10 rounded': active, 'text-black text-opacity-20': disabled }"
    @click="$emit('do')"
    :disabled="disabled"
  >
    <VIcon
      class="text-3xl"
      :icon="icon"
    />
  </button>
</template>
<script>

export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['do'],
};

</script>
